import { createContext, useEffect, useState } from 'react';

import { useAlert } from 'contexts/alert/useAlert';
import {
  Newsletter,
  NewsletterListItem,
  PageTypeSetting,
  PublicationSettingSearchableContentType,
  UiBasicTreatmentType,
  UiModuleType,
  useNewsletterListItemsQuery
} from 'data/generated/graphql';
import noop from 'utils/noop';
import { mockPageTypeSetting } from './mockCampaign';

interface NewsletterContext {
  newsletter: Newsletter | null;
  handlePartialNewsletterChange: (newsletter: Partial<Newsletter>) => void;
  newsletterItems: NewsletterListItem[] | undefined;
  pageTypeSetting: PageTypeSetting;
}

const DEFAULT_NEWSLETTER_STATE: NewsletterContext = {
  newsletter: null,
  handlePartialNewsletterChange: noop,
  newsletterItems: [],
  pageTypeSetting: mockPageTypeSetting
};

export const NewsletterContext = createContext<NewsletterContext>(DEFAULT_NEWSLETTER_STATE);

interface NewsletterProviderProps {
  children: React.ReactNode;
  currentProperty: string;
}

export const NewsletterProvider = ({ currentProperty, children }: NewsletterProviderProps) => {
  const [newsletter, setNewsletter] = useState<Newsletter | null>(null);

  const { data: newslettersItemData } = useNewsletterListItemsQuery({
    publicationKey: currentProperty
  });

  const { alertWarning } = useAlert();

  useEffect(() => {
    // @ts-expect-error Just providing the pageModules property when initializing a new newsletter
    setNewsletter({
      pageModules: [
        {
          moduleItems: [],
          uiModuleType: UiModuleType.UiBasicModuleType,
          uiModuleFields: {
            basicModule: {
              treatmentType: UiBasicTreatmentType.Audience,
              contentTypes: [PublicationSettingSearchableContentType.Article],
              allowedTreatmentTypes: null
            }
          }
        }
      ]
    });
  }, []);

  const handlePartialNewsletterChange = (newNewsletter: Partial<Newsletter>) => {
    if (!newsletter) return;
    const updatedPage: Newsletter = { ...newsletter, ...newNewsletter };
    if (updatedPage.pageModules[0].moduleItems.length > 1) {
      alertWarning('Newsletters only take one article. All but the first article will be ignored on publish.', {
        autoDismiss: true
      });
    }
    setNewsletter({ ...updatedPage });
  };

  const value = {
    newsletter,
    handlePartialNewsletterChange,
    newsletterItems: newslettersItemData?.getNewsletterList,
    pageTypeSetting: mockPageTypeSetting
  };

  return <NewsletterContext.Provider value={value}>{children}</NewsletterContext.Provider>;
};
