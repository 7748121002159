/*
Newsletter          Default send time (ET)
Weekend Reads       Fridays, noon
Mutual Funds        Thursdays, 9 am
ETF Wrap            Thursdays, 5 pm
Need to Know        Daily, 7:30 am
Distributed Ledger  Daily, 4 pm ET

From here: https://dowjones.atlassian.net/browse/ET-7315
*/

export const getTimeZoneOffsetByName = (timeZone: string | undefined) => {
  if (!timeZone) return null;
  const str = new Date().toLocaleString('en', { timeZone, timeZoneName: 'longOffset' });
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [_, h, m] = str.match(/([+-]\d+):(\d+)$/) ?? ['', '+00', '00'];
  return (Number(h) * 60 + (Number(h) > 0 ? +m : -m)) * -1;
};

export const getNextQuarterHour = () => {
  const date = new Date();
  const minutes = date.getUTCMinutes();
  const nextQuarter = Math.ceil(minutes / 15) * 15;

  if (nextQuarter === 60) {
    date.setUTCHours(date.getUTCHours() + 1);
    date.setUTCMinutes(0);
  } else {
    date.setUTCMinutes(nextQuarter);
  }
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

interface NewsletterFilters {
  [currentProperty: string]: {
    [newsletterName: string]: {
      sectionType: string;
    };
  };
}

export const NEWSLETTER_FILTERS: NewsletterFilters = {
  marketwatch: {
    'Distributed Ledger': {
      sectionType: 'Distributed Ledger'
    },
    'Mutual Funds Weekly': {
      sectionType: 'Mutual Funds Weekly'
    },
    'ETF Wrap': {
      sectionType: 'ETF Wrap'
    },
    'Need to Know': {
      sectionType: 'Need to Know'
    }
  }
};
