import { cloneDeep } from 'lodash';

import { AltSummFields } from 'contexts/summarian/summarianReducer';
import { SnippetyImageData, VideoDataForSummarian } from 'hooks/useSnippetyQuery';
import { MediaTypes } from './components/media-input/MediaInput.utils';

export interface ControlledAltSumm {
  headline: string;
  body: string;
  image: SnippetyImageData[];
  video: VideoDataForSummarian[];
  imageUrl: string; // we don't send this property to Summarian, we only use it to display the image in the modal
  videoUrl: string; // we also do not send this to Summarian, only use it to display the video in the modal
  videoImage: string; // we do not send this to Summarian, only use it to display the video image
  bullets: string[];
  subhead: string;
  chartlosId: string;
  chart: string;
}

export const DEFAULT_CONTROLLED_ALT_SUMM: ControlledAltSumm = {
  headline: '',
  body: '',
  subhead: '',
  bullets: [],
  image: [],
  video: [],
  imageUrl: '',
  videoUrl: '',
  videoImage: '',
  chart: '',
  chartlosId: ''
};

export const DEFAULT_MEDIA_ALT_SUMM = {
  image: [],
  video: [],
  imageUrl: '',
  videoUrl: '',
  videoImage: '',
  chart: '',
  chartlosId: ''
};

export function getUrlToShowType(altSummFields?: AltSummFields | null): MediaTypes {
  if (!altSummFields) {
    return '';
  }
  const { image, video, chartlosId, iframe } = altSummFields;
  if (iframe) {
    return 'iframe';
  }

  if (video) {
    return 'video';
  }
  if (chartlosId) {
    return 'chart';
  }
  if (image) {
    return 'image';
  }
  return '';
}

export function getUrlToShow(altSummFields: AltSummFields | null): string {
  if (!altSummFields) {
    return '';
  }
  const { image, videoImage, video, chart, chartlosId, iframe } = altSummFields;
  if (iframe) {
    return iframe;
  }
  if (videoImage && video) {
    return videoImage;
  }
  if (chart && chartlosId) {
    return chart;
  }
  if (image?.length) {
    return image;
  }
  return '';
}

export function massageControlledAltSummBeforeSendingToSummarian(controlledAltSum: ControlledAltSumm) {
  const clonedControlledAltSumm = cloneDeep(controlledAltSum);
  if (clonedControlledAltSumm.image.length > 0) {
    clonedControlledAltSumm.video = DEFAULT_CONTROLLED_ALT_SUMM.video;
    clonedControlledAltSumm.videoUrl = DEFAULT_CONTROLLED_ALT_SUMM.videoUrl;
    clonedControlledAltSumm.videoImage = DEFAULT_CONTROLLED_ALT_SUMM.videoImage;
    clonedControlledAltSumm.chart = DEFAULT_CONTROLLED_ALT_SUMM.chart;
    clonedControlledAltSumm.chartlosId = DEFAULT_CONTROLLED_ALT_SUMM.chartlosId;
  } else if (clonedControlledAltSumm.video.length > 0) {
    clonedControlledAltSumm.image = DEFAULT_CONTROLLED_ALT_SUMM.image;
    clonedControlledAltSumm.imageUrl = DEFAULT_CONTROLLED_ALT_SUMM.imageUrl;
    clonedControlledAltSumm.chart = DEFAULT_CONTROLLED_ALT_SUMM.chart;
    clonedControlledAltSumm.chartlosId = DEFAULT_CONTROLLED_ALT_SUMM.chartlosId;
  } else if (clonedControlledAltSumm.chartlosId) {
    clonedControlledAltSumm.image = DEFAULT_CONTROLLED_ALT_SUMM.image;
    clonedControlledAltSumm.imageUrl = DEFAULT_CONTROLLED_ALT_SUMM.imageUrl;
    clonedControlledAltSumm.video = DEFAULT_CONTROLLED_ALT_SUMM.video;
    clonedControlledAltSumm.videoUrl = DEFAULT_CONTROLLED_ALT_SUMM.videoUrl;
    clonedControlledAltSumm.videoImage = DEFAULT_CONTROLLED_ALT_SUMM.videoImage;
  }
  return clonedControlledAltSumm;
}
