export const DROPPABLE_ID_PREFIXES = {
  MODULE_ITEMS: 'MODULE_ITEMS#', // a specific module box area
  CONTENT: 'CONTENT#', // content search area
  MODULES: 'MODULES#', // draft module list area
  COLLECTION: 'COLLECTION#', // collection search area
  COLLECTION_ITEMS: 'COLLECTION_ITEMS#', // collection items in draft area
  HISTORY: 'HISTORY#', // history module list area
  TRASH: 'TRASH#', // trash area in search results
  ITP_SECTION: 'ITP_SECTION#', // page from ITP issue
  OFF_PLATFORM: 'OFF_PLATFORM#'
};

// these are used to differentiate card items in different sections
export const DRAGGABLE_PREFIXES = {
  MODULE: 'MODULE#', // full module
  MODULE_ITEM: 'MODULE_ITEM#', // a module item within a module
  COLLECTION_ITEM: 'COLLECTION_ITEM#', // an item within a collection
  HISTORY_MODULE_ITEM: 'HISTORY_MODULE_ITEM#' // a module item within a module within history
};

export const DROPPABLE_TYPES = {
  MODULE: 'MODULE',
  CONTENT: 'CONTENT'
};
