/* eslint-disable import/no-cycle */
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Typography, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import CollectionArticle from 'components/datamodel/content/Article/subtypes/CollectionArticle/CollectionArticle';
import { MenuActionsKeys } from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DRAGGABLE_PREFIXES, DROPPABLE_ID_PREFIXES } from 'contexts/drag-and-drop/dragUtils';
import { ArticleItem } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { isContentIdExcludedInQueryRules } from 'utils/queryUtils';
import { safelyParseContent } from 'utils/temp';
import styles from './ManualItems.module.scss';

interface ManualItemsProps {
  articles: ArticleItem[];
  allessehJsonQuery: string | null;
  setJsonQueryStr: (newQueryStr: string) => void;
  listHierarchyId: string;
}

export const ManualItems = ({ articles, allessehJsonQuery, setJsonQueryStr, listHierarchyId }: ManualItemsProps) => {
  const { generateHierarchyId } = useDataModelContext();
  const { renderActions } = useContextMenuActions();

  return (
    <Droppable droppableId={DROPPABLE_ID_PREFIXES.COLLECTION_ITEMS + listHierarchyId}>
      {(droppableProvided, snapshot) => (
        <div
          data-testid="curated-list-container"
          className={classnames(styles.itemsList, { [styles.itemsListEmpty]: articles.length === 0 })}
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {articles.length === 0 ? (
            <Wrapper
              data-testid="curated-list-empty-list"
              className={classnames(styles.emptyCuratedList, {
                [styles.emptyCuratedListDragging]: snapshot.isDraggingOver
              })}
              padding={{ all: 'md' }}
            >
              <Typography data-testid="curated-list-empty-label" variant="note">
                Drop story here
              </Typography>
            </Wrapper>
          ) : (
            articles.map((article, index) => {
              const fullHierarchyId = generateHierarchyId(article, listHierarchyId, index);
              return (
                <Draggable key={index} draggableId={DRAGGABLE_PREFIXES.COLLECTION_ITEM + fullHierarchyId} index={index}>
                  {(draggableProvided, snapshot) => (
                    <div
                      data-testid="curated-list-item"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <CollectionArticle
                        data={article}
                        parentHierarchyId={listHierarchyId}
                        index={index}
                        isDragging={snapshot.isDragging}
                        renderActions={() =>
                          renderActions(MenuActionsKeys.DraftArticle, {
                            entity: article,
                            hierarchyId: fullHierarchyId,
                            index,
                            extraProperties: {
                              allessehContent: safelyParseContent<AllessehContent>(article.content),
                              jsonQueryStr: allessehJsonQuery ?? undefined,
                              setJsonQueryStr,
                              isContentIdExcludedInQueryRules: isContentIdExcludedInQueryRules(
                                article.attributes.id,
                                allessehJsonQuery
                              )
                            }
                          })
                        }
                      />
                    </div>
                  )}
                </Draggable>
              );
            })
          )}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
