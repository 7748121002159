/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Alert, Box, Button, Checkbox, FormHelperText, FormLabel, Group, Input, Textarea } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { LinkedItem } from 'data/generated/graphql';
import useControlledQuery from 'hooks/altsumm/useControlledQuery';
import { usePublicationSettings } from 'hooks/publication-settings';
import { useAuthToken } from 'hooks/useAuthToken';
import { SnippetyResponse, SnippetyResponseWithImage } from 'hooks/useSnippetyQuery';
import { isValidURL } from 'utils/url';
import styles from './LinkedItemForm.module.scss';

interface LinkedItemFormProps {
  defaultHeadline: string;
  defaultMedia?: { name: string } | null;
  defaultSummary?: string | null;
  defaultValidationBypassed?: boolean;
  defaultUrl: string;
  hierarchyId: string;
}

const urlErrorMessages = {
  notFound: 'Cannot get data from this link, you need to manually input the data.',
  invalidUrl: 'Invalid URL!',
  notExternal: 'Link items are only for external resources! Please use Content Items instead.'
};

const imageErrorMessages = {
  notFound: 'Image not found!',
  invalidID: 'Invalid Image ID. ID format must be "im-[some numbers]"'
};

const IM_ID_REGEX = /[iI][mM]-\d{4,}/i;
const ONLY_IM_ID = new RegExp(`^${IM_ID_REGEX.source}$`);

export const LinkedItemForm = ({
  defaultHeadline,
  defaultMedia,
  defaultSummary,
  defaultUrl,
  defaultValidationBypassed,
  hierarchyId
}: LinkedItemFormProps) => {
  const authToken = useAuthToken();
  const defaultImId = defaultMedia?.name.match(IM_ID_REGEX)?.[0] ?? '';

  const [errors, setErrors] = useState<string[]>([]);
  const [headline, setHeadline] = useState(defaultHeadline);
  const [media, setMedia] = useState(defaultImId);
  const [summary, setSummary] = useState(defaultSummary ?? '');
  const [currentUrl, setCurrentUrl] = useState(defaultUrl);
  const [previousUrl, setPreviousUrl] = useState('');
  const [bypassValidation, setBypassValidation] = useState<boolean>(!!defaultValidationBypassed);
  const [mediaValidationError, setMediaValidationError] = useState('');
  const [urlValidationError, setCurrentUrlValidationError] = useState<string | null>(null);

  const { currentProperty, currentPropertyObject, user } = useAuth();
  const { data } = usePublicationSettings();

  const {
    value: imageValue,
    setControlledQuery: setControlledQueryImage,
    data: imageSnippetyData,
    error: imageSnippetyError,
    isLoading: isFetchingImageDataFromSnippety
  } = useControlledQuery('image', { value: '' }, authToken, data?.publicationSetting);

  const {
    value: urlValue,
    setControlledQuery: setControlledQueryUrl,
    data: urlSnippetyData,
    error: urlSnippetyError,
    isLoading: isFetchingUrlDataFromSnippety
  } = useControlledQuery('url', { value: '' }, authToken, data?.publicationSetting);

  const { setIsEditingLinkedItem, removeEntity, insertEntity } = useDataModelContext();

  const totalWords = summary.length ? summary.trim().split(' ').length : 0;
  const isEditingExistingLinkedItem = !!defaultUrl;
  const isInternalUrl = urlValidationError === urlErrorMessages.notExternal;
  const isAdminUser = !!user?.app_admin;
  const showBypassValidationCheckbox = isInternalUrl && isAdminUser;

  const typedUrlSnippetyData =
    !urlSnippetyError && urlSnippetyData ? (urlSnippetyData as SnippetyResponse | null) : null;
  const typedImageSnippetyData =
    !imageSnippetyError && imageSnippetyData ? (imageSnippetyData as SnippetyResponseWithImage | null) : null;

  const setData = useCallback(() => {
    if (typedUrlSnippetyData?.title) {
      setHeadline(typedUrlSnippetyData.title);
    }
    if (typedUrlSnippetyData?.desc) {
      setSummary(typedUrlSnippetyData.desc);
    }
  }, [typedUrlSnippetyData]);

  const handleUrlOnBlur = () => {
    setPreviousUrl(currentUrl);
    if (currentUrl === '') {
      setCurrentUrlValidationError(null);
    } else if (isValidURL(currentUrl) && currentUrl !== previousUrl) {
      setControlledQueryUrl({ value: currentUrl });
    } else if (!isValidURL(currentUrl)) {
      setCurrentUrlValidationError(urlErrorMessages.invalidUrl);
    } else {
      setCurrentUrlValidationError(null);
    }
  };

  const handleImageOnBlur = () => {
    if (media && ONLY_IM_ID.test(media)) {
      setMediaValidationError('');
      setControlledQueryImage({ value: media });
    } else if (media) {
      setMediaValidationError(imageErrorMessages.invalidID);
    }
  };

  const handleBypassValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBypassValidation(e.target.checked);
    if (e.target.checked) {
      setCurrentUrlValidationError(null);
      setData();
    } else {
      setCurrentUrlValidationError(urlErrorMessages.notExternal);
    }
  };

  useEffect(() => {
    if (!typedUrlSnippetyData && currentUrl && currentUrl !== previousUrl) {
      setControlledQueryUrl({ value: currentUrl });
    }
    if (!typedImageSnippetyData && media && !mediaValidationError && ONLY_IM_ID.test(media)) {
      setControlledQueryImage({ value: media });
    } else if (!media) {
      setControlledQueryImage({ value: '' });
    }
  }, [
    currentUrl,
    media,
    typedImageSnippetyData,
    typedUrlSnippetyData,
    previousUrl,
    setControlledQueryUrl,
    setControlledQueryImage,
    mediaValidationError
  ]);

  useEffect(() => {
    if (imageSnippetyError?.error) {
      setMediaValidationError(imageErrorMessages.notFound);
    }
    if (urlSnippetyError?.error) {
      setCurrentUrlValidationError(urlErrorMessages.notFound);
    }
  }, [urlSnippetyError, imageSnippetyError]);

  useEffect(() => {
    if (
      typedUrlSnippetyData &&
      currentProperty &&
      typedUrlSnippetyData.provider?.includes(currentProperty) &&
      !bypassValidation
    ) {
      setCurrentUrlValidationError(urlErrorMessages.notExternal);
      return;
    }
    setCurrentUrlValidationError(null);
    setData();
  }, [currentProperty, setData, typedUrlSnippetyData, bypassValidation]);

  const validation: string[] = [
    !headline && 'headline',
    !currentUrl && 'url',
    media && !IM_ID_REGEX.test(media) && 'media'
  ].filter((v: string | false): v is string => !!v);

  const handleSubmit = () => {
    const linkedItem: LinkedItem = {
      type: 'LinkedItem',
      attributes: {
        id: currentUrl,
        hosted_url: currentUrl,
        product: data!.publicationSetting.publicationKey.toUpperCase(),
        title: headline,
        description: summary,
        image: null
      }
    };
    if (typedImageSnippetyData !== null) {
      const {
        type,
        id,
        tools: {
          image: { caption, credit, reuseType, height, width, src, href, aspectRatio }
        }
      } = typedImageSnippetyData;
      linkedItem.attributes.image = {
        credit,
        media_type: type,
        name: href,
        reuse_type: reuseType,
        height,
        width,
        alternate_text: caption,
        id,
        src: {
          image_id: src?.imageId ?? '',
          path: src?.imageId ?? '',
          base_url: src?.baseUrl ?? '',
          params: src?.params ?? { size: aspectRatio }
        }
      };
    }
    removeEntity(hierarchyId);
    insertEntity(hierarchyId, linkedItem);

    setIsEditingLinkedItem('');
  };

  const submitDisabled = !!(
    !!validation.length ||
    !!(urlValidationError && urlValidationError !== urlErrorMessages.notFound) ||
    !!mediaValidationError ||
    imageValue !== media ||
    urlValue !== currentUrl ||
    isFetchingImageDataFromSnippety ||
    isFetchingUrlDataFromSnippety
  );

  return (
    <Box>
      <Box.Title className={styles.title} padding={{ left: 'md', right: 'sm', vertical: 'md' }}>
        Add Linked Item
      </Box.Title>

      <Box.Content padding={{ all: 'md' }}>
        {!!errors.length && (
          <Alert onDismiss={() => setErrors([])} type="error">
            The following field{errors.length > 1 ? 's are' : ' is'} empty or invalid: {errors.join(', ')}.
          </Alert>
        )}
        <Group direction="column" gap="md">
          <FormLabel fullWidth label="URL" required>
            <Input
              error={
                !!(errors.includes('url') || !!(urlValidationError && urlErrorMessages.notFound !== urlValidationError))
              }
              onBlur={() => handleUrlOnBlur()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentUrl(e.target.value)}
              value={currentUrl}
              placeholder="Add URL"
              disabled={isFetchingUrlDataFromSnippety}
            />
            {urlValidationError && (
              <FormHelperText error={urlErrorMessages.notFound !== urlValidationError}>
                {urlValidationError}
              </FormHelperText>
            )}
            {showBypassValidationCheckbox && (
              <FormLabel label="Bypass Validation" labelPosition="right">
                <Checkbox
                  name="Bypass Validation"
                  value={bypassValidation}
                  checked={bypassValidation}
                  onChange={handleBypassValidation}
                />
              </FormLabel>
            )}
          </FormLabel>
          <FormLabel fullWidth label="Image">
            <Input
              error={!!(errors.includes('media') || mediaValidationError)}
              onBlur={() => handleImageOnBlur()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMedia(e.target.value);
                if (ONLY_IM_ID.test(e.target.value) || !e.target.value) setMediaValidationError('');
              }}
              placeholder={` ${currentPropertyObject?.name} Image Manager ID`}
              value={media}
              disabled={isFetchingImageDataFromSnippety}
            />
            {(errors.includes('media') || mediaValidationError) && (
              <FormHelperText error>{mediaValidationError}</FormHelperText>
            )}
          </FormLabel>
          <FormLabel fullWidth label="Headline" required>
            <Input
              error={errors.includes('headline')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHeadline(e.target.value)}
              placeholder="Headline"
              value={headline}
              disabled={isFetchingUrlDataFromSnippety}
            />
          </FormLabel>
          <FormLabel fullWidth label="Summary">
            <Textarea
              error={errors.includes('summary')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSummary(e.target.value)}
              placeholder="Summary"
              value={summary}
              disabled={isFetchingUrlDataFromSnippety}
            />
            <Group.Item align="flex-end">
              <FormHelperText>
                {totalWords} {totalWords === 1 ? 'word' : 'words'}
              </FormHelperText>
            </Group.Item>
          </FormLabel>

          <Group align="end" fullWidth valign="end">
            <Group gap="md">
              <Button
                onClick={() => {
                  if (!isEditingExistingLinkedItem) removeEntity(hierarchyId);
                  setIsEditingLinkedItem('');
                }}
                secondary
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (validation.length) {
                    setErrors(validation);
                  } else {
                    handleSubmit();
                  }
                }}
                disabled={submitDisabled}
                primary
              >
                Save linked item
              </Button>
            </Group>
          </Group>
        </Group>
      </Box.Content>
    </Box>
  );
};
