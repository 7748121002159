import { createRef, useEffect, useMemo, useState } from 'react';
import { Button, IconEdit, IconExternalLink, IconTrash, Input, List, Wrapper } from '@screentone/core';

import PortalDropdown from 'components/portal-dropdown/PortalDropdown';
import { SnippetyResponse, useSnippetyQuery } from 'hooks/useSnippetyQuery';
import { decodeHtmlEntities } from 'utils/text';
import { formatBulletLink, isValidURL } from 'utils/url';
import styles from './BulletInput.module.scss';

interface BulletInputProps {
  bullets: string[];
  handleUpdateBullets: (bullets: string[]) => void;
  displayNewBulletInput: boolean;
  setDisplayNewBulletInput: (displayNewBulletInput: boolean) => void;
}

export const BulletInput = ({
  bullets,
  handleUpdateBullets,
  displayNewBulletInput,
  setDisplayNewBulletInput
}: BulletInputProps) => {
  const [newBullet, setNewBullet] = useState('');
  const [editInputIndex, setEditInputIndex] = useState<number | undefined>(undefined);
  const { refetch: refetchSnippetyData, fetchStatus } = useSnippetyQuery(newBullet, { enabled: false });
  const bulletRefs = useMemo(
    () => Array.from({ length: bullets.length }).map(() => createRef<HTMLTextAreaElement>()),
    [bullets.length]
  );
  const isFetchingFromSnippety = fetchStatus === 'fetching';

  const scriptTagRegex = /<a[^>]*href=["']([^"']*)["']/g;
  const handleNewBulletInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBullet(e.target.value);
  };

  const handleNewBulletEnter = async (e: React.KeyboardEvent) => {
    const { key, type } = e;
    let bulletLink;
    let fetchedArticle;
    if (key === 'Enter' || type === 'blur') {
      if (isValidURL(newBullet)) {
        try {
          fetchedArticle = await refetchSnippetyData();
        } catch (error) {
          console.error('Error fetching article data:', error);
        }
        const dataFromSnippety = fetchedArticle?.data as SnippetyResponse | undefined;
        if (dataFromSnippety?.title) {
          bulletLink = formatBulletLink(newBullet, dataFromSnippety.title);
        } else {
          bulletLink = formatBulletLink(newBullet);
        }
      } else {
        bulletLink = formatBulletLink(newBullet);
      }

      handleUpdateBullets([...bullets, bulletLink]);
      setNewBullet('');
      setDisplayNewBulletInput(false);
    }
  };

  useEffect(() => {
    if (bulletRefs.length > 0) {
      bulletRefs.forEach((_bulletRefItem, bulletIndex) => {
        if (bulletRefs[bulletIndex].current) {
          bulletRefs[bulletIndex].current!.style.height = '0';
          bulletRefs[bulletIndex].current!.style.height = `${bulletRefs[bulletIndex].current!.scrollHeight}px`;
        }
      });
    }
  }, [bulletRefs]);

  const handleRemoveBullet = (index: number) => {
    const newBullets = [...bullets];
    newBullets.splice(index, 1);
    handleUpdateBullets(newBullets);
  };

  const handleEditBulletIndex = (index: number) => {
    setEditInputIndex(index);
  };

  const handleEditBulletInput = (
    e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>,
    index: number,
    bulletHeadline?: boolean
  ) => {
    if (bulletHeadline) {
      if (bulletRefs[index]) {
        bulletRefs[index].current!.style.height = '0';
        bulletRefs[index].current!.style.height = `${bulletRefs[index].current!.scrollHeight}px`;
      }
      const newBullets = [...bullets];
      const bulletLinkRegex = scriptTagRegex.exec(newBullets[index]);
      const bulletLink = newBullets[index].split('">')[0].slice(9);

      if (bulletLinkRegex) {
        newBullets[index] = formatBulletLink(bulletLinkRegex[1], e.target.value);
      } else {
        newBullets[index] = formatBulletLink(bulletLink, e.target.value);
      }
      handleUpdateBullets(newBullets);
    } else {
      const newBullets = [...bullets];
      const headline = newBullets[index].split('">')[1].slice(0, -4);
      if (headline) {
        newBullets[index] = formatBulletLink(e.target.value, decodeHtmlEntities(headline));
      }
      handleUpdateBullets(newBullets);
    }
  };

  const handleEditBulletEnter = (e: React.KeyboardEvent) => {
    const { key, type } = e;
    if (key === 'Enter' || type === 'blur') {
      setEditInputIndex(-1);
    }
  };

  const handleViewBulletLink = (bulletLink: string) => {
    window.open(bulletLink, '_blank');
  };

  return (
    <>
      <List listStyle="bullet">
        {bullets.map((bullet: string, index: number) => {
          if (!bullet) return null;
          const link = bullet.split('">')[0].slice(9);
          const headline = bullet.split('">')[1].slice(0, -4);
          const bulletString = decodeHtmlEntities(headline);
          return (
            <List.Item key={index}>
              <Wrapper className={styles.bulletItemWrapper}>
                <Wrapper className={styles.bulletGroup}>
                  {editInputIndex === index && (
                    <Input
                      data-testid="edit-bullet-input"
                      tabIndex={0}
                      defaultValue={link}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleEditBulletInput(e, index);
                      }}
                      onKeyDown={handleEditBulletEnter}
                      onBlur={handleEditBulletEnter}
                    />
                  )}
                  <textarea
                    hidden={editInputIndex === index && !headline.length}
                    value={bulletString}
                    placeholder="Add article link"
                    className={styles.bulletItem}
                    ref={bulletRefs[index]}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleEditBulletInput(e, index, true);
                    }}
                  />
                </Wrapper>
                <PortalDropdown>
                  <Button
                    onMouseDown={() => {
                      handleViewBulletLink(link);
                    }}
                    margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                    tertiary
                    icon={IconExternalLink as SvgComponent}
                  >
                    Open Link
                  </Button>
                  <Button
                    onMouseDown={() => {
                      handleEditBulletIndex(index);
                    }}
                    margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                    tertiary
                    icon={IconEdit as SvgComponent}
                  >
                    Edit Link
                  </Button>
                  <Button
                    onMouseDown={() => {
                      handleRemoveBullet(index);
                    }}
                    margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                    tertiary
                    color="lava"
                    icon={IconTrash as SvgComponent}
                  >
                    Remove Bullet
                  </Button>
                </PortalDropdown>
              </Wrapper>
            </List.Item>
          );
        })}
      </List>
      {displayNewBulletInput && (
        <List listStyle="bullet">
          <List.Item>
            <Input
              data-testid="edit-bullet-input"
              value={newBullet}
              onChange={handleNewBulletInput}
              onKeyDown={handleNewBulletEnter}
              onBlur={handleNewBulletEnter}
              disabled={isFetchingFromSnippety}
              placeholder="Add article URL …"
            />
            <PortalDropdown>
              <Button
                margin={{ horizontal: 'sm', top: 'sm', bottom: 'sm' }}
                tertiary
                icon={IconTrash as SvgComponent}
                color="lava"
                onMouseDown={() => {
                  setNewBullet('');
                  setDisplayNewBulletInput(false);
                }}
              >
                Remove Bullet
              </Button>
            </PortalDropdown>
          </List.Item>
        </List>
      )}
    </>
  );
};
