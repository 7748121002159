import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Box, Button, Group, IconLogIn, IconMinusCircle, Loader, Typography } from '@screentone/core';
import { cloneDeep } from 'lodash';

import { useAlert } from 'contexts/alert/useAlert';
import { useIssue } from 'contexts/issue/useIssue';
import { ItpPage, PageModule, PageModuleItem, useImportItpContentQuery } from 'data/generated/graphql';
import { useHighlight } from 'features/issues/hooks/useNewHighlight';
import { getAllContentIds, getAllContentIdsInCollection } from 'features/page-edit/pageEditUtils';
import { useAuraQuery } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AuraEmployeeResponse } from 'hooks/useAuraQuery';
import { formatInTimeZone } from 'utils/dates';

// return whether page is active for this issue
// by checking the isHidden property
// something the property is not there at all
function isPageActive(page: { isHidden?: boolean }): boolean {
  return !page.isHidden || !page.hasOwnProperty('isHidden');
}
function getUserText(userData: AuraEmployeeResponse | undefined, userId: string) {
  return userData ? `${userData.first_name ?? ''} ${userData.last_name ?? ''}` : userId;
}

const IssueSettings = () => {
  const { currentProperty } = useAuth();
  const {
    handlePartialIssueChange,
    issue,
    refreshedUtc,
    setRefreshedUtc,
    setStaleIds,
    clearErrorState,
    defaultItpPages
  } = useIssue();
  const { addItems, clearItems } = useHighlight();
  const { alertError } = useAlert();
  const [shouldUpdateIssue, setShouldUpdateIssue] = useState(false);
  const [isFirstImport, setIsFirstImport] = useState(!refreshedUtc);

  const activePages: ItpPage[] = useMemo(
    () => (issue?.pages ?? []).filter((page) => isPageActive(page)),
    [issue?.pages]
  );

  const { data: publicationSettings } = usePublicationSettings();
  const { data: importedContent, isFetching: isFetchingImportedContent } = useImportItpContentQuery(
    {
      publicationKey: currentProperty ?? 'wsj',
      itpImportContentInput: {
        currentPages: activePages.map((page) => ({
          contentIds: [],
          pageType: page.pageType,
          label: page.label
        })),
        isFirstImport
      }
    },
    { enabled: shouldUpdateIssue, staleTime: 30 * 1000 }
  );
  const { data: revisorUserData, isLoading: isFetchingUserData } = useAuraQuery(issue?.revisorUser?.djUserId ?? '');
  const { data: creatorUserData, isLoading: isFetchingCreatorUserData } = useAuraQuery(
    issue?.creatorUser.djUserId ?? ''
  );

  const creatorUserDataText = getUserText(creatorUserData, issue?.creatorUser.djUserId ?? '');
  const revisorUserText = getUserText(revisorUserData, issue?.revisorUser?.djUserId ?? '');

  const { lastPublishedDate, lastUpdatedDate, createdDate } = useMemo(() => {
    const result = { lastPublishedDate: '', lastUpdatedDate: '', createdDate: '' };

    result.lastPublishedDate = !issue?.publishUtc
      ? ''
      : formatInTimeZone(
          new Date(issue.publishUtc),
          publicationSettings?.publicationSetting.preferredTimezone,
          'MM/dd/yyyy hh:mm aa',
          { showTimezoneAbbreviation: true }
        );
    result.lastUpdatedDate = !issue?.updatedUtc
      ? ''
      : formatInTimeZone(
          new Date(issue.updatedUtc),
          publicationSettings?.publicationSetting.preferredTimezone,
          'MM/dd/yyyy hh:mm aa',
          { showTimezoneAbbreviation: true }
        );
    result.createdDate = !issue?.createdUtc
      ? ''
      : formatInTimeZone(
          new Date(issue.createdUtc),
          publicationSettings?.publicationSetting.preferredTimezone,
          'MM/dd/yyyy hh:mm aa',
          { showTimezoneAbbreviation: true }
        );
    return result;
  }, [issue, publicationSettings]);

  const handleEmptyAllContent = () => {
    if (!issue) return;

    // eslint-disable-next-line
    if (confirm('Are you sure? This will remove all content from the issue.')) {
      const newIssue = { ...issue };

      newIssue.pages = cloneDeep(defaultItpPages);
      clearItems();
      setRefreshedUtc(null);
      setIsFirstImport(true);
      setShouldUpdateIssue(false);

      clearErrorState();
      handlePartialIssueChange(newIssue);
    }
  };

  useEffect(() => {
    if (shouldUpdateIssue && !isFetchingImportedContent) {
      const importedModules = importedContent?.importITPContent as PageModule[][] | undefined;
      if (!importedModules) {
        alertError('Failed to import content');
        setShouldUpdateIssue(false);
        return;
      }

      // is this pulling from the correct state?
      const currentIds: string[] =
        issue?.pages
          .map((p) => p.pageModules.map((pm) => pm.moduleItems.map((mi) => mi.itemFields.contentItem?.originId)))
          .flat(2)
          .filter((id): id is string => id !== undefined) ?? [];

      let idx = 0;
      const newIds: string[] = [];
      const pageErrors: string[] = [];

      // Merge the imported content with the existing content
      const pages: ItpPage[] = issue!.pages.map((page) => {
        const oldContentIds = [
          ...getAllContentIds(page.pageModules),
          ...getAllContentIdsInCollection(page.pageModules)
        ];
        const newContentIds = getAllContentIds(importedModules[idx]);
        const allPageModulesForThisPage = importedModules[idx];

        newIds.push(...newContentIds);

        /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        const pageModulesForThisPage = allPageModulesForThisPage?.map((m) => {
          const modulesCopy = cloneDeep(m);
          modulesCopy.moduleItems = modulesCopy.moduleItems.filter(
            (mi) => !oldContentIds.includes(mi.itemFields.contentItem?.originId ?? '')
          );

          return modulesCopy;
        });

        if (!isPageActive(page)) {
          // If page is not active, return it as-is, since we didn't get any content from the import query
          return page;
        }
        if (pageModulesForThisPage.length === 0) {
          // If there was no content for a page, return the page as-is
          idx++;
          return page;
        }
        if (pageModulesForThisPage[0].error) {
          // If there was an error importing content for a page, add the error message to the list of pageErrors
          pageErrors.push(pageModulesForThisPage[0].error);
          idx++;
          return page;
        }

        let combinedModules: PageModule[];
        if (isFirstImport) {
          combinedModules = pageModulesForThisPage.map<PageModule>((pageModule, i) => {
            let combinedItems: PageModuleItem[];
            if (page.pageModules[i]) {
              combinedItems = [...page.pageModules[i].moduleItems, ...pageModule.moduleItems];
            } else {
              combinedItems = pageModule.moduleItems;
            }

            return { ...pageModule, moduleItems: combinedItems };
          });
        } else {
          combinedModules = page.pageModules.map((pageModule, i) => {
            if (i === page.pageModules.length - 1) {
              const filteredNewPageModuleItems = pageModulesForThisPage[0].moduleItems.filter(
                (mi) => !currentIds.includes(mi.itemFields.contentItem?.originId ?? '')
              );
              const combinedItems: PageModuleItem[] = [...pageModule.moduleItems, ...filteredNewPageModuleItems];

              const hasOldItems = pageModule.moduleItems.length > 0;
              const hasNewItems = pageModulesForThisPage[0].moduleItems.length > 0;
              if (hasOldItems && hasNewItems) {
                // Highlight new items, but only if there is content on the page already
                // const newItemsIds = getAllContentIds(pageModulesForThisPage);
                const newItemsIds = filteredNewPageModuleItems
                  .map((mi) => mi.itemFields.contentItem?.originId)
                  .filter((id): id is string => id !== undefined);
                addItems(newItemsIds);
              }

              return { ...pageModule, moduleItems: combinedItems };
            }

            return pageModule;
          });
        }

        idx++;

        return { ...page, pageModules: combinedModules };
      });

      handlePartialIssueChange({
        ...issue,
        pages
      });
      setRefreshedUtc(Date.now());
      setShouldUpdateIssue(false);
      setIsFirstImport(false);
      alertError(pageErrors.join('\n'), { wrapNewlines: true });
      setStaleIds(currentIds.filter((id) => !newIds.includes(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateIssue, isFetchingImportedContent]);

  const handleImportContentClick = () => {
    clearErrorState();
    setShouldUpdateIssue(true);
  };

  // check if every page module is empty
  const disableEmptyAll = issue?.pages.every((p) => p.pageModules.every((pm) => !pm.moduleItems.length));

  return (
    <>
      <Box margin={{ bottom: 'md' }}>
        <Box.Title>Data</Box.Title>
        <Box.Content>
          <Group direction="column" gap="mlg">
            <Group direction="column" gap="xs" data-testid="itp-date-time-msg">
              <Typography variant="label3">Date/Time last pulled from ITP Tool</Typography>
              {refreshedUtc && (
                <Typography color="ink" data-testid="itp-published-pull-date">
                  {formatInTimeZone(
                    new Date(refreshedUtc),
                    publicationSettings?.publicationSetting.preferredTimezone,
                    'MM/dd/yyyy hh:mm aa',
                    { showTimezoneAbbreviation: true }
                  )}
                </Typography>
              )}
              {!refreshedUtc && <Typography color="asphalt">N/A</Typography>}
            </Group>

            <Button
              tertiary
              icon={IconLogIn as SvgComponent}
              onClick={handleImportContentClick}
              disabled={shouldUpdateIssue}
              data-testid="itp-import-content-button"
            >
              Import content
            </Button>

            <Typography variant="note">
              New items will appear at the bottom of their respective pages/modules
            </Typography>
            {lastPublishedDate && (
              <Group direction="column" gap="xs" data-testid="itp-setting-last-published-date">
                <Typography variant="label3">Last Published</Typography>
                <Typography color="ink">{lastPublishedDate}</Typography>
              </Group>
            )}
            {lastUpdatedDate && revisorUserText ? (
              <Typography variant="note">
                Last edited by {isFetchingUserData ? <Loader size="md" /> : revisorUserText} on {lastUpdatedDate}
              </Typography>
            ) : (
              <Typography variant="note" data-testid="itp-created-by-date">
                Created by {isFetchingCreatorUserData ? <Loader size="md" /> : creatorUserDataText} on {createdDate}
              </Typography>
            )}
          </Group>
        </Box.Content>
      </Box>
      <Box>
        <Box.Title>Issue</Box.Title>
        <Box.Content>
          <Button
            color="lava"
            disabled={disableEmptyAll}
            icon={IconMinusCircle as SvgComponent}
            onClick={handleEmptyAllContent}
            tertiary
            data-testid="itp-empty-all-content-button"
          >
            Empty all content
          </Button>
        </Box.Content>
      </Box>
    </>
  );
};

export default IssueSettings;
