import { createContext, useEffect, useState } from 'react';
import { format } from 'date-fns-tz';

import { PageError } from 'contexts/alert/AlertContext';
import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { DEFAULT_ALT_SUM_ID } from 'contexts/summarian/SummarianContext';
import {
  AllessehContentType,
  PageDto,
  PageDtoUpdateInput,
  ScheduledContentCancelInput,
  ScheduledContentCreateInput,
  ScheduledContentRescheduleInput,
  ScheduledContentSaveDraftInput,
  ScheduledContentUpdateInput,
  SectionContainer,
  useCancelScheduledContentMutation,
  usePageDtoUpdateMutation,
  useRescheduleContentMutation,
  useSaveDraftContentMutation,
  useScheduleContentMutation,
  useUpdateScheduledContentMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { asyncNoop as noop } from 'utils/noop';

interface PagePublishContext {
  canSave: boolean;
  canPublish: boolean;
  hasPageChanged: boolean;
  hasRecentlyCreatedCollection: boolean | null;
  hasRecentlyPublished: boolean;
  hasRecentlyScheduled: boolean;
  originalPageWhenInHistoryEditMode: PageDto | null;
  setHasRecentlyCreatedCollection: (hasRecentlyCreatedCollection: boolean) => void;
  handleShowHistoryEditMode: (historyPage: PageDto) => void;
  handleHideHistoryEditMode: () => void;
  handlePublish: (publishUtc?: number) => Promise<void>;
  isPublishing: boolean;
  handleSaveDraft: () => Promise<void>;
  isSavingDraft: boolean;
  handleSchedule: (publishUtc: number) => Promise<void>;
  isScheduling: boolean;
  handleReschedule: (publishUtc: number) => Promise<void>;
  isRescheduling: boolean;
  handleUpdateSchedule: () => Promise<void>;
  isUpdatingSchedule: boolean;
  handleDeleteSchedule: () => Promise<void>;
  isDeleting: boolean;
}

const DEFAULT_PAGE_PUBLISH_STATE: PagePublishContext = {
  canSave: false,
  canPublish: false,
  hasPageChanged: false,
  hasRecentlyCreatedCollection: null,
  hasRecentlyPublished: false,
  hasRecentlyScheduled: false,
  originalPageWhenInHistoryEditMode: null,
  setHasRecentlyCreatedCollection: noop,
  handleShowHistoryEditMode: noop,
  handleHideHistoryEditMode: noop,
  handlePublish: noop,
  isPublishing: false,
  handleSaveDraft: noop,
  isSavingDraft: false,
  handleSchedule: noop,
  isScheduling: false,
  handleReschedule: noop,
  isRescheduling: false,
  handleUpdateSchedule: noop,
  isUpdatingSchedule: false,
  handleDeleteSchedule: noop,
  isDeleting: false
};

export const DEFAULT_PAGE_CONFIG = {
  summaryPriority: DEFAULT_ALT_SUM_ID
};

export const PagePublishContext = createContext(DEFAULT_PAGE_PUBLISH_STATE);

interface PagePublishProviderProps {
  children: React.ReactNode;
  setData: (data: PageDto) => void;
}

export const PagePublishProvider = ({ children, setData }: PagePublishProviderProps) => {
  const { alertError, alertSuccess, removeAlert, alertModules } = useAlert();
  const [originalPageWhenInHistoryEditMode, setOriginalPageWhenInHistoryEditMode] = useState<PageDto | null>(null);
  const [hasRecentlyPublished, setHasRecentlyPublished] = useState<boolean>(false); // used to animate in success notification
  const [hasRecentlyScheduled, setHasRecentlyScheduled] = useState<boolean>(false); // used to reset page history
  const [hasRecentlyCreatedCollection, setHasRecentlyCreatedCollection] = useState<boolean>(false);
  const currentProperty = useConvertedProperty();
  const {
    getDTO,
    setNewRoot,
    setNewMetadata,
    hasModelChangedOnce,
    hasModelChangedRecently,
    setHasModelChangedRecently,
    resetModelChanged
  } = useDataModelContext();
  const [canPublish, setCanPublish] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);

  const { mutateAsync: pageUpdateMutateAsync, isLoading: isPublishing } = usePageDtoUpdateMutation();
  const { mutateAsync: pageScheduleMutateAsync, isLoading: isScheduling } = useScheduleContentMutation();
  const { mutateAsync: pageRescheduleMutateAsync, isLoading: isRescheduling } = useRescheduleContentMutation();
  const { mutateAsync: pageUpdateScheduleMutateAsync, isLoading: isUpdatingSchedule } =
    useUpdateScheduledContentMutation();
  const { mutateAsync: pageCancelScheduleMutateAsync, isLoading: isDeleting } = useCancelScheduledContentMutation();
  const { mutateAsync: pageSaveDraftMutateAsync, isLoading: isSavingDraft } = useSaveDraftContentMutation();

  const handleShowHistoryEditMode = (historyPage: PageDto) => {
    const page = getDTO() as PageDto;
    setOriginalPageWhenInHistoryEditMode(page);
    setNewRoot(historyPage.root);
    setNewMetadata(historyPage.metadata);
  };

  const handleHideHistoryEditMode = () => {
    if (originalPageWhenInHistoryEditMode) {
      setNewRoot(originalPageWhenInHistoryEditMode.root);
      setNewMetadata(originalPageWhenInHistoryEditMode.metadata);
    }
    setOriginalPageWhenInHistoryEditMode(null);
  };

  const handlePublish = async () => {
    // dedupe();
    const pageDTO = getDTO(true);

    removeAlert();

    const updateInput: PageDtoUpdateInput = {
      pageDTO,
      publicationKey: currentProperty ?? ''
    };

    try {
      const { pageDTOUpdate } = await pageUpdateMutateAsync({
        pageUpdateInput: updateInput
      });
      setHasRecentlyPublished(true);
      alertSuccess('Published page.');
      setData(pageDTOUpdate as PageDto);
      setNewRoot(pageDTOUpdate.root as SectionContainer);
      setNewMetadata(pageDTOUpdate.metadata);
      setCanPublish(false);
      setCanSave(false);
      resetModelChanged();
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not publish the page.';
      if (e instanceof Error) {
        if (e.message.startsWith('PAGE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('PAGE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${ERROR_MESSAGE}\n${pageError.message.replace(/; /g, '\n')}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handleSaveDraft = async () => {
    const pageDTO = getDTO(true);

    const input: ScheduledContentSaveDraftInput = {
      allessehId: pageDTO.metadata.allessehId,
      contentType: AllessehContentType.Page,
      body: pageDTO
    };

    try {
      await pageSaveDraftMutateAsync({ publicationKey: currentProperty ?? '', scheduledContentSaveDraftInput: input });
      setCanSave(false);
      setHasModelChangedRecently(false);
      alertSuccess('Saved draft of page.');
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not save draft of the page.';
      if (e instanceof Error) {
        if (e.message.startsWith('PAGE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('PAGE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${ERROR_MESSAGE}\n${pageError.message.replace(/; /g, '\n')}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handleSchedule = async (publishUtc: number) => {
    const pageDTO = getDTO(true);

    pageDTO.metadata.publishUtc = publishUtc;

    const input: ScheduledContentCreateInput = {
      allessehId: pageDTO.metadata.allessehId,
      contentType: AllessehContentType.Page,
      publishUtc,
      body: pageDTO
    };

    try {
      await pageScheduleMutateAsync({ publicationKey: currentProperty ?? '', scheduledContentCreateInput: input });
      resetModelChanged();
      setHasRecentlyScheduled(true);
      alertSuccess(`Scheduled page for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not schedule the page.';
      if (e instanceof Error) {
        if (e.message.startsWith('PAGE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('PAGE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${ERROR_MESSAGE}\n${pageError.message.replace(/; /g, '\n')}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handleReschedule = async (publishUtc: number) => {
    const pageDTO = getDTO(true);

    const previousPublishUtc = pageDTO.metadata.publishUtc!;
    pageDTO.metadata.publishUtc = publishUtc;

    const input: ScheduledContentRescheduleInput = {
      allessehId: pageDTO.metadata.allessehId,
      contentType: AllessehContentType.Page,
      previousPublishUtc,
      newPublishUtc: publishUtc,
      body: pageDTO
    };

    try {
      await pageRescheduleMutateAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentRescheduleInput: input
      });
      resetModelChanged();
      setHasRecentlyScheduled(true);
      alertSuccess(`Rescheduled page for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);
      handleHideHistoryEditMode();
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not reschedule the page.';
      if (e instanceof Error) {
        if (e.message.startsWith('PAGE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('PAGE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${ERROR_MESSAGE}\n${pageError.message.replace(/; /g, '\n')}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handleUpdateSchedule = async () => {
    const pageDTO = getDTO(true);

    const input: ScheduledContentUpdateInput = {
      allessehId: pageDTO.metadata.allessehId,
      publishUtc: pageDTO.metadata.publishUtc!,
      contentType: AllessehContentType.Page,
      body: pageDTO
    };

    try {
      await pageUpdateScheduleMutateAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentUpdateInput: input
      });
      resetModelChanged();
      setHasRecentlyScheduled(true);
      alertSuccess('Scheduled Page has been updated.');
      handleHideHistoryEditMode();
    } catch (e) {
      const ERROR_MESSAGE = 'Could not update scheduled page.';
      if (e instanceof Error) {
        if (e.message.startsWith('PAGE_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('PAGE_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${ERROR_MESSAGE}\n${pageError.message.replace(/; /g, '\n')}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  const handleDeleteSchedule = async () => {
    const pageDTO = getDTO(true);

    const input: ScheduledContentCancelInput = {
      allessehId: pageDTO.metadata.allessehId,
      publishUtc: pageDTO.metadata.publishUtc!
    };

    try {
      await pageCancelScheduleMutateAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentCancelInput: input
      });
      resetModelChanged();
      setHasRecentlyScheduled(true);
      alertSuccess('Scheduled Page has been deleted.');
      handleHideHistoryEditMode();
    } catch (e: unknown) {
      const ERROR_MESSAGE = 'Could not delete scheduled page.';
      if (e instanceof Error) {
        alertError(`${ERROR_MESSAGE} ${e.message}`);
      } else {
        alertError(ERROR_MESSAGE);
      }
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (hasRecentlyPublished) {
      timeout = setTimeout(() => {
        setHasRecentlyPublished(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasRecentlyPublished]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (hasRecentlyScheduled) {
      timeout = setTimeout(() => {
        setHasRecentlyScheduled(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasRecentlyScheduled]);

  useEffect(() => {
    setCanPublish(hasModelChangedOnce);
    setCanSave(hasModelChangedOnce);
  }, [hasModelChangedOnce]);

  useEffect(() => {
    if (hasModelChangedRecently) {
      setCanPublish(true);
      setCanSave(true);
      setHasModelChangedRecently(false);
    }
  }, [hasModelChangedRecently, setCanPublish, setCanSave, setHasModelChangedRecently]);

  const value = {
    hasPageChanged: hasModelChangedOnce,
    hasRecentlyCreatedCollection,
    hasRecentlyPublished,
    hasRecentlyScheduled,
    originalPageWhenInHistoryEditMode,
    setHasRecentlyCreatedCollection,
    handleShowHistoryEditMode,
    handleHideHistoryEditMode,
    handlePublish,
    isPublishing,
    handleSaveDraft,
    isSavingDraft,
    handleSchedule,
    isScheduling,
    handleReschedule,
    isRescheduling,
    handleUpdateSchedule,
    isUpdatingSchedule,
    handleDeleteSchedule,
    isDeleting,
    canSave,
    canPublish
  };

  return <PagePublishContext.Provider value={value}>{children}</PagePublishContext.Provider>;
};
