import { useSearchParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { NewsletterProvider } from 'contexts/newsletter/NewsletterContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import { NewsletterEdit } from 'features/newsletters/NewsletterEdit';
import { useConvertedProperty } from 'hooks';

const NewsletterEditPage = () => {
  const [searchParams] = useSearchParams();
  const newsletterId = searchParams.get('newsletter');
  const currentProperty = useConvertedProperty();

  if (!currentProperty || !newsletterId || currentProperty !== 'marketwatch') return <ErrorPage type="404" />;

  return (
    <PageRootContainer>
      <SummarianProvider>
        <NewsletterProvider currentProperty={currentProperty}>
          <TrashProvider>
            <NewsletterEdit newsletterId={newsletterId} />
          </TrashProvider>
        </NewsletterProvider>
      </SummarianProvider>
    </PageRootContainer>
  );
};

export default NewsletterEditPage;
