import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import { PublicationSetting } from 'data/generated/graphql';
import {
  getSnippetyData,
  SnippetyError,
  SnippetyResponseWithChart,
  SnippetyResponseWithImage,
  SnippetyResponseWithVideo
} from 'hooks/useSnippetyQuery';
import { getDomain } from './useMediaForm';

type MediaTypes = 'chart' | 'video' | 'image' | 'url';

const DEFAULT_IMAGE_WIDTH = '300';

export const DEFAULT_CHART_OBJECT = {
  chart: '',
  chartlosId: ''
};

export const DEFAULT_VIDEO_OBJECT = {
  videoImage: '',
  video: '',
  videoUrl: ''
};

interface Query {
  value: string;
  id?: string;
}

interface UseControlledQueryReturn {
  value: string;
  setControlledQuery: (query: Query | null) => void;
  data: SnippetyResponseWithChart | SnippetyResponseWithImage | SnippetyResponseWithVideo | null;
  error: SnippetyError | null;
  isLoading: boolean;
}

function useControlledQuery(
  type: MediaTypes,
  initialQuery: Query | null,
  authToken: string,
  publicationSetting?: PublicationSetting,
  dispatch?: (action: { type: 'UPDATE_CONTENT_ITEM'; payload: any }) => void
): UseControlledQueryReturn {
  const [controlledQuery, setControlledQuery] = useState(initialQuery);
  const [snippetyData, setSnippetyData] = useState<
    SnippetyResponseWithChart | SnippetyResponseWithImage | SnippetyResponseWithVideo | null
  >(null);
  const [snippetyError, setSnippetyError] = useState<SnippetyError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const hasFetched = useRef<string | null>(null);

  const setControlledQueryWithLoading = debounce((query: Query | null) => {
    if (query?.value === controlledQuery?.value) return;
    if (query?.value && hasFetched.current !== query.value) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    setControlledQuery(query);
  }, 300);

  useEffect(() => {
    async function queryData() {
      if (!controlledQuery?.value || hasFetched.current === controlledQuery.value) return;
      hasFetched.current = controlledQuery.value;

      try {
        const { value, id } = controlledQuery;
        const domain = getDomain(publicationSetting, type, value);
        const snippetyData = await getSnippetyData(authToken, domain);

        let dispatchParams = {};
        if (snippetyData?.error) {
          setSnippetyError(snippetyData as SnippetyError);
          setSnippetyData(null);
          setIsLoading(false);
          if (type === 'chart') {
            dispatchParams = DEFAULT_CHART_OBJECT;
          } else if (type === 'video') {
            dispatchParams = DEFAULT_VIDEO_OBJECT;
          }
          dispatch?.({
            type: 'UPDATE_CONTENT_ITEM',
            payload: { id, fields: dispatchParams }
          });
          return;
        }

        if (type === 'chart') {
          const snippetyChartData = snippetyData as SnippetyResponseWithChart;
          const height = snippetyChartData.tools.chart.minHeightPerWidth[DEFAULT_IMAGE_WIDTH];
          dispatchParams = {
            chart: `${snippetyChartData.media}?height=${height}&width=${DEFAULT_IMAGE_WIDTH}`,
            chartlosId: `cdc_${snippetyChartData.tools.chart.id}`
          };
          setSnippetyData(snippetyChartData);
        } else if (type === 'video') {
          setSnippetyData(snippetyData as SnippetyResponseWithVideo);
          dispatchParams = {
            videoImage: (snippetyData as SnippetyResponseWithVideo).media,
            video: (snippetyData as SnippetyResponseWithVideo).url,
            videoUrl: (snippetyData as SnippetyResponseWithVideo).url
          };
        } else {
          setSnippetyData(snippetyData as SnippetyResponseWithImage);
        }

        setSnippetyError(null);
        if (dispatch) {
          dispatch({ type: 'UPDATE_CONTENT_ITEM', payload: { id, fields: dispatchParams } });
        }
      } catch (error) {
        console.error(`Error fetching snippety data for ${type}`, error);
        setSnippetyError({ error: true, message: 'Error fetching snippety data', code: 'snippety_error' });
      } finally {
        setIsLoading(false);
      }
    }

    queryData().catch((e) => {
      console.error(`Error fetching snippety data for ${type}`, e);
      setSnippetyError({ error: true, message: 'Error fetching snippety data', code: 'snippety_error' });
    });
  }, [controlledQuery, authToken, publicationSetting, dispatch, type]);

  return {
    value: controlledQuery?.value ?? '',
    setControlledQuery: setControlledQueryWithLoading,
    data: snippetyData,
    error: snippetyError,
    isLoading
  };
}

export default useControlledQuery;
