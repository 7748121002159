import { Button, Token, Typography, Wrapper } from '@screentone/core';

import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { PublicationSetting } from 'data/generated/graphql';
import { NewsletterScheduleDialog } from '../newsletter-schedule-dialog/NewsletterScheduleDialog';
import { NewsletterTestDialog } from '../newsletter-send-test-dialog/NewsletterSendTestDialog';

import styles from 'features/page-edit/components/page-title-header/PageTitleHeader.module.scss';

interface PageTitleHeaderProps {
  newsletterName?: string;
  status?: string;
  readOnly?: boolean;
  isScheduled?: boolean;
  newsletterId?: string;
  handleSendNowClick?: () => void;
  handleScheduleClick?: (publishUtc: number) => void;
  handleSendTestClick: (emails: string) => void;
  publicationSetting?: PublicationSetting;
}

export const PageTitleHeader = ({
  newsletterName,
  handleSendNowClick,
  readOnly,
  handleScheduleClick,
  status,
  isScheduled = false,
  handleSendTestClick,
  newsletterId,
  publicationSetting
}: PageTitleHeaderProps) => (
  <Wrapper padding={{ top: 'md', bottom: 'md' }} className={styles.titleHeader}>
    <div className={styles.row}>
      <Typography variant="header2" margin={{ bottom: 'none' }}>
        {newsletterName}
      </Typography>
      {status && <Token color={isScheduled ? 'blurple' : 'emerald'}>{status}</Token>}
    </div>
    <div className={styles.row}>
      <ExternalLink contentId={newsletterId ?? ''} type={ExternalLinkContentType.COLLECTION} isPublished={!!status} />
      {(!readOnly || isScheduled) && (
        <>
          <NewsletterTestDialog sendTestEmail={handleSendTestClick} />
          <Button secondary onClick={handleSendNowClick}>
            Send Now
          </Button>
          <NewsletterScheduleDialog
            reschedule={isScheduled}
            handleSchedule={handleScheduleClick}
            publicationSetting={publicationSetting}
          />
        </>
      )}
    </div>
  </Wrapper>
);
