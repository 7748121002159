import { Button, Group, IconEye, IconRefresh, Typography, Wrapper } from '@screentone/core';
import { format } from 'date-fns-tz';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { usePagePublish } from 'contexts/page-publish-dto/usePagePublish';
import { usePageTypeSettingsContext } from 'contexts/page-type-settings/usePageTypeSettings';
import { ContentUnion, ModuleContainer, QueryItem, SectionContainer } from 'data/generated/graphql';
import { usePagePreview } from 'features/section-page-edit/hooks/usePagePreview';
import styles from './PageDraftSection.module.scss';

export const PageDraftSection = () => {
  const { root, metadata, renderEntity, insertEntity, removeAllByType, countContent } =
    useDataModelContext<SectionContainer>();
  const { originalPageWhenInHistoryEditMode } = usePagePublish();
  const { currentLayout } = usePageTypeSettingsContext();
  const { handlePreviewPage, isLoading: isPreviewPageLoading } = usePagePreview();

  const handleResetToDefault = () => {
    removeAllByType('Module');

    currentLayout?.requiredLayoutModules.forEach((module, i) => {
      // TODO: Handle other module types, currently applicable to Feeds
      const collection = module.moduleItems
        .map((moduleItem) => {
          if (moduleItem.itemFields.queryItem) {
            const queryItem: QueryItem = {
              type: 'Query',
              attributes: {
                isFeed: moduleItem.itemFields.queryItem.isFeed,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                query: JSON.parse(JSON.stringify(moduleItem.itemFields.queryItem.jsonQuery)),
                feedTitle: moduleItem.itemFields.queryItem.title,
                feedUrl: moduleItem.itemFields.queryItem.url
              }
            };
            return queryItem;
          }
          return null;
        })
        .filter((item) => item !== null) as ContentUnion[];

      const newModule: ModuleContainer = {
        type: 'Module',
        attributes: { pageModule: module },
        collection
      };

      insertEntity(`0-0-${i}`, newModule);
    });
  };

  const draftSection = renderEntity(root, { hierarchyId: '' });

  return (
    <>
      <Wrapper margin={{ bottom: 'sm' }} className={styles.topHeaderWrapper}>
        <Group data-testid="page-draft-title" direction="row" align="space-between" fullWidth valign="baseline">
          <Typography variant="header3" margin={{ bottom: 'none', top: 'mlg' }}>
            {originalPageWhenInHistoryEditMode
              ? `Editing: ${format(metadata.publishUtc!, 'MMM d, yyyy h:mm aaa z')}`
              : `${countContent(root.collection[0].collection)}`}
          </Typography>
          <Button
            tertiary
            icon={IconEye as SvgComponent}
            onClick={() => handlePreviewPage()}
            disabled={isPreviewPageLoading}
            data-testid="page-preview-button"
          >
            Preview
          </Button>
        </Group>
      </Wrapper>
      <Wrapper className={styles.draftContainer}>{draftSection}</Wrapper>
      <div className={styles.buttonsSection}>
        <Button
          tertiary
          icon={IconRefresh as SvgComponent}
          color="lava"
          onClick={handleResetToDefault}
          data-testid="page-reset-default-button"
        >
          Reset to default
        </Button>
      </div>
    </>
  );
};
