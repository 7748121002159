/* eslint-disable import/no-cycle */
import { createContext } from 'react';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import {
  AvailableLayoutModule,
  AvailableRequiredLayoutModule,
  Layout,
  PageModule,
  PageType,
  PageTypeSetting,
  SectionContainer,
  TreatmentTypeSetting,
  UiModule,
  UiModuleType,
  usePageTypeSettingByPublicationKeyPageTypeQuery
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';

interface PageTypeSettingsContext {
  pageTypeSetting: PageTypeSetting | undefined;
  currentLayout: Layout | undefined;
  addableUiModules: AvailableLayoutModule[] | undefined;
  removableUiModules: (AvailableLayoutModule | AvailableRequiredLayoutModule)[];
  availableRequiredLayoutModules: AvailableRequiredLayoutModule[] | undefined;
  getTreatmentTypeSetting: (pageModule: PageModule) => TreatmentTypeSetting | undefined;
}

const DEFAULT_STATE: PageTypeSettingsContext = {
  pageTypeSetting: {} as PageTypeSetting,
  currentLayout: {} as Layout,
  addableUiModules: [],
  removableUiModules: [],
  availableRequiredLayoutModules: [],
  getTreatmentTypeSetting: () => undefined
};

interface PageTypeSettingsProviderProps {
  children: React.ReactNode;
  pageType?: PageType;
}

export const PageTypeSettingsContext = createContext(DEFAULT_STATE);

const getTreatmentType = (pageModule: PageModule): string => {
  let treatmentType = null;
  const keys = Object.keys(pageModule.uiModuleFields);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const uiModuleFieldKey = key as keyof UiModule;
    const uiModule = pageModule.uiModuleFields[uiModuleFieldKey] as { treatmentType: string } | null;
    if (uiModule) {
      treatmentType = uiModule.treatmentType;
    }
  }
  return treatmentType as string;
};

export const PageTypeSettingsProvider = ({ pageType, children }: PageTypeSettingsProviderProps) => {
  const currentProperty = useConvertedProperty();
  const { root } = useDataModelContext<SectionContainer>();

  const getAllUiModuleTypes = (pageModules?: PageModule[]): UiModuleType[] =>
    (pageModules ?? []).reduce<UiModuleType[]>((acc, curr) => {
      if (curr.uiModuleType === UiModuleType.UiTwoColumnModuleType) {
        return acc
          .concat(getAllUiModuleTypes(curr.uiModuleFields.twoColumnModule?.leftModules))
          .concat(getAllUiModuleTypes(curr.uiModuleFields.twoColumnModule?.rightModules));
      }
      return acc.concat([curr.uiModuleType]);
    }, []);
  const { data: pageTypeSettingsResp } = usePageTypeSettingByPublicationKeyPageTypeQuery(
    {
      publicationKey: currentProperty ?? '',
      pageType: pageType ?? PageType.Home
    },
    { enabled: !!currentProperty }
  );
  const pageTypeSetting = pageTypeSettingsResp?.pageTypeSettingByPublicationKeyPageType as PageTypeSetting | undefined;

  const currentLayout = pageTypeSetting?.layouts.filter((l) => l.id === root.collection[0].attributes.layoutId)[0];
  const allUsedUiModuleTypes = getAllUiModuleTypes(
    root.collection[0].collection.map((module) => module.attributes.pageModule as PageModule)
  );

  const addableUiModules = currentLayout?.availableLayoutModules.filter((availableModule) => {
    const availableUiModuleType = availableModule.uiModuleType;
    const usedUiModulesForType = allUsedUiModuleTypes.filter(
      (usedUiModuleType) => usedUiModuleType === availableUiModuleType
    );
    return usedUiModulesForType.length < availableModule.maximumAllowed;
  });

  const removableUiModules = [
    ...(currentLayout?.availableLayoutModules.filter((availableModule) => {
      const availableUiModuleType = availableModule.uiModuleType;
      const usedUiModulesForType = allUsedUiModuleTypes.filter(
        (usedUiModuleType) => usedUiModuleType === availableUiModuleType
      );
      return usedUiModulesForType.length > availableModule.minimumAllowed;
    }) ?? []),
    ...(currentLayout?.availableRequiredLayoutModules ?? [])
  ];

  const availableRequiredLayoutModules = pageTypeSetting?.layouts[0]?.availableRequiredLayoutModules;

  const getTreatmentTypeSetting = (pageModule: PageModule) => {
    const treatmentType = getTreatmentType(pageModule);
    const treatmentTypeSetting = pageTypeSetting?.treatmentTypeSettings.filter(
      (t) => t.treatmentType === treatmentType || t.treatmentTypeKey === treatmentType
    )[0];

    return treatmentTypeSetting;
  };

  const value = {
    pageTypeSetting,
    currentLayout,
    addableUiModules,
    removableUiModules,
    availableRequiredLayoutModules,
    getTreatmentTypeSetting
  };
  return <PageTypeSettingsContext.Provider value={value}>{children}</PageTypeSettingsContext.Provider>;
};
