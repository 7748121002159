import { createContext, useEffect, useState } from 'react';

import { PageModule, UiModuleType } from 'data/generated/graphql';
import noop from 'utils/noop';

export interface PageModuleValidationError {
  uiModuleType: UiModuleType;
  numUsedItems: number;
  minRequiredItems: number;
  pathToModule: string;
}

export interface PageValidationError {
  moduleErrors: PageModuleValidationError[];
  modulesWithValidationError: PageModule[];
  pageName: string;
  pagePublishUtc: number;
}

export interface ITPIssueValidationError {
  issueDate: string;
  pageErrors: PageValidationError[];
}

export interface PageError {
  message: string;
  validationError?: PageValidationError;
}

export interface IssueError {
  message: string;
  validationError?: ITPIssueValidationError;
}

interface AlertState {
  message: string | null;
  type: string | null;
  autoDismiss?: boolean;
  wrapNewlines?: boolean;
  pageValidationError?: PageValidationError;
}

export interface AlertOptions {
  autoDismiss?: boolean;
  wrapNewlines?: boolean;
}

interface AlertContext {
  alertState: AlertState;
  removeAlert: () => void;
  alertSuccess: (message: string, options?: AlertOptions) => void;
  alertWarning: (message: string, options?: AlertOptions) => void;
  alertError: (message: string, options?: AlertOptions) => void;
  alertModules: (pageValidationError: PageValidationError) => void;
}

const noopAlert: (message: string) => void = () => {
  /* do nothing */
};

const DEFAULT_ALERT_STATE: AlertState = {
  message: null,
  type: null,
  wrapNewlines: false
};

const DEFAULT_ALERT_CONTEXT: AlertContext = {
  alertState: DEFAULT_ALERT_STATE,
  removeAlert: noop,
  alertSuccess: noopAlert,
  alertWarning: noopAlert,
  alertError: noopAlert,
  alertModules: noop
};

export const AlertContext = createContext(DEFAULT_ALERT_CONTEXT);

export const AlertProvider: FCC = ({ children }) => {
  const [alertState, setAlertState] = useState(DEFAULT_ALERT_STATE);
  const handleRemoveAlert = () => {
    setAlertState(DEFAULT_ALERT_STATE);
  };

  const alert = (type: string) => (message: string, options?: AlertOptions) => {
    setAlertState({
      message,
      type,
      ...options
    });
  };

  const alertModules = (pageValidationError: PageValidationError) => {
    setAlertState((prev) => ({ ...prev, pageValidationError }));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (alertState.type === 'success') {
      timeout = setTimeout(() => {
        handleRemoveAlert();
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [alertState]);

  const value = {
    alertState,
    removeAlert: handleRemoveAlert,
    alertSuccess: alert('success'),
    alertWarning: alert('warning'),
    alertError: alert('error'),
    alertModules
  };

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};
