import { FormLabel, Select, Typography, Wrapper } from '@screentone/core';

import { HelpIcon } from 'components';
import { PageModule, TreatmentTypeSetting, UiModule } from 'data/generated/graphql';
import styles from './PageModuleShared.module.scss';

interface PageModuleSharedProps {
  children: React.ReactNode;
  moduleFieldKey: Exclude<keyof UiModule, '__typename'>;
  pageModule: PageModule;
  selectedTreatmentType: string;
  setSelectedTreatmentType: (value: string) => void;
  allowedTreatmentTypeSettings?: TreatmentTypeSetting[];
  showCurrentTreatmentTypeAtTheTop?: boolean;
  viewOnly?: boolean;
}

export const PageModuleShared = ({
  children,
  moduleFieldKey,
  pageModule,
  selectedTreatmentType,
  setSelectedTreatmentType,
  allowedTreatmentTypeSettings,
  showCurrentTreatmentTypeAtTheTop,
  viewOnly
}: PageModuleSharedProps) => {
  if (showCurrentTreatmentTypeAtTheTop) {
    const currentTreatmentType = pageModule.uiModuleFields[moduleFieldKey]?.treatmentType;

    const currTreatmentTypeIndex = allowedTreatmentTypeSettings?.findIndex(
      (treatmentTypeSetting) =>
        (treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType) ===
        (currentTreatmentType as string)
    );

    allowedTreatmentTypeSettings?.unshift(allowedTreatmentTypeSettings[currTreatmentTypeIndex!]);
    allowedTreatmentTypeSettings?.splice(currTreatmentTypeIndex! + 1, 1);
  }

  const handleSelectedTreatmentType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTreatmentType(event.target.value);
  };

  return (
    <>
      {children}
      <FormLabel
        key="treatment"
        label={
          <Wrapper className={styles.labelHeader}>
            <Typography margin={{ bottom: 'none' }} variant="label1" weight="bold" className={styles.labelText}>
              Treatment Type
            </Typography>
            <HelpIcon
              text="The treatment type defines the module's layout that is presented to the user. Some treatment types are not editable based on the page's design."
              isLeftAnchored
            />
          </Wrapper>
        }
        fullWidth
        margin={{ top: 'sm' }}
      >
        <Select
          type="text"
          value={selectedTreatmentType}
          onChange={handleSelectedTreatmentType}
          disabled={allowedTreatmentTypeSettings?.length === 1 || viewOnly}
          data-testid="page-treatment-type-select"
        >
          {allowedTreatmentTypeSettings?.map((treatmentTypeSetting) => (
            <option
              key={treatmentTypeSetting.treatmentType}
              value={treatmentTypeSetting.treatmentTypeKey ?? treatmentTypeSetting.treatmentType}
            >
              {treatmentTypeSetting.label}
            </option>
          ))}
        </Select>
      </FormLabel>
    </>
  );
};
