import { useState } from 'react';
import { Button, Typography } from '@screentone/core';

import { ScheduleDialog } from 'components/schedule-dialog/ScheduleDialog';
import { PublicationSetting } from 'data/generated/graphql';
import { getNextQuarterHour } from 'utils/newsletters';

export const NewsletterScheduleDialog = ({
  handleSchedule,
  reschedule = false,
  publicationSetting
}: {
  handleSchedule?: (publishUtc: number) => void;
  reschedule?: boolean;
  publicationSetting?: PublicationSetting;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const now = getNextQuarterHour();
  const initialTime = `${now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()}:${
    now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
  }`;

  const afterCalendarContent = publicationSetting ? (
    <Typography variant="note">
      All times are in {publicationSetting.preferredTimezone.timezoneAbbreviation}.
    </Typography>
  ) : null;

  return (
    <>
      <Button primary onClick={() => setModalOpen(true)}>
        {reschedule ? 'Reschedule' : 'Schedule'}
      </Button>
      {modalOpen && handleSchedule && (
        <ScheduleDialog
          dialogTitle="Schedule Newsletter"
          onSchedule={handleSchedule}
          initialDate={now}
          initialTime={initialTime}
          onCancel={() => setModalOpen(false)}
          afterCalendarContent={afterCalendarContent}
        />
      )}
    </>
  );
};
